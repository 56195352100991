:root {
	--white: #ffffff;
	--black: #000000;
	--grey: #808080;
	--PrimaryColor: #25c3dd;
	--shadowColor: #88888888;
	--navLinkHoverBackgroundColor: #2ae1fd;
	--navMobileBackgroundColor: #b7fff9;
	--payNowBtn: #303030;
	--tableCellBackground: #d1d1d1;
	--textColor: #363636;
	--shadowTextColor: #f9f9f9;
}

html {
	scroll-behavior: smooth;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box !important;
}

/* Navbar */
.ah-container-fluid {
	padding: 0 4rem !important;
}
.ah-navbar {
	background-color: var(--white) !important;
	box-shadow: 0 0 0 0.2rem var(--shadowColor);
	position: fixed !important;
	right: 0;
	left: 0;
	z-index: 1000;
}
.ah-nav-link {
	background-color: var(--PrimaryColor);
	color: var(--white) !important;
	padding: 0.5rem 1rem !important;
	border-radius: 0.5rem;
	transition-duration: 1s !important;
}
.ah-nav-link:hover {
	color: var(--black) !important;
	background-color: var(--navLinkHoverBackgroundColor);
}
figure {
	margin: 0;
	padding: 0;
}
.ah-navbar-brand {
	max-width: 300px;
	height: 2.5rem;
}

@media (max-width: 1000px) {
	.ah-container-fluid {
		padding: 0 0.6rem !important;
	}
	.ah-nav-link {
		padding: 1rem !important;
		margin: 0.4rem !important;
	}
	.navbar-collapse {
		background-color: var(--navMobileBackgroundColor) !important;
	}
	.ah-navbar-brand {
		max-width: 15.625rem;
	}
}

/* Home Page */
.home-section {
	position: relative;
}
.home-description {
	position: absolute;
	top: 4rem;
	left: 5rem;
}
.sub-home-description {
	width: 75% !important;
}
.home-description h1 {
	color: var(--white);
	line-height: 4rem;
	text-align: justify;
	font-size: 1.8em;
}
.ah-contact {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-right: 3rem;
}
.ah-btn {
	margin-top: 1rem;
	position: relative;
	border: 0.08rem solid var(--PrimaryColor) !important;
	font-size: 1.1em !important;
	z-index: 111;
	background: none;
	background-color: transparent;
	outline: none !important;
}
.ah-btn::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	background-color: var(--PrimaryColor);
	width: 0%;
	height: 100%;
	z-index: 1;
	transition: 0.5s;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
}
.ah-btn:hover:after {
	transition: 0.5s;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
	width: 100%;
	z-index: -1;
}
.nav-to-contact {
	text-decoration: none;
	color: var(--PrimaryColor) !important;
}
.nav-to-contact:hover {
	color: var(--white) !important;
}

@media (max-width: 1000px) {
	.home-description h1 {
		font-size: 2em;
		line-height: 3rem;
	}
	.home-description {
		top: 3rem;
		left: 4rem;
	}
}
@media (max-width: 768px) {
	.home-description h1 {
		font-size: 1.2em;
		line-height: 2.5rem;
	}
	.home-description {
		top: 2rem;
		left: 3rem;
	}
	.sub-home-description {
		width: 85% !important;
	}
}
@media (max-width: 576px) {
	.home-description h1 {
		font-size: 0.8em;
		line-height: 1.6rem;
	}
	.home-description {
		top: 1rem;
		left: 1.5rem;
	}
	.ah-contact-btn {
		margin-top: 0.3rem;
	}
	.sub-home-description {
		width: 90% !important;
	}
	.sub-home-description h1 {
		margin-bottom: 0;
	}
	.ah-contact .ah-btn {
		margin-top: 0.2rem;
	}
	.service-details {
		padding: 1rem !important;
	}
}

/* About Section */
.ah-container {
	padding-left: 4rem !important;
	padding-right: 4rem !important;
}
.ah-about h2 {
	font-size: 3rem;
	color: var(--shadowTextColor);
	margin-top: 0;
	font-weight: 900;
	position: absolute;
	line-height: 3rem;
}

.ah-about h3 {
	font-size: 1.5rem;
	font-family: "Lato", sans-serif;
	letter-spacing: 0.1rem;
	color: var(--PrimaryColor);
	margin-top: 0;
	font-weight: 900;
	line-height: 1.5rem;
	position: relative;
}
.ah-about p {
	font-size: 1rem;
	font-family: "Lato", sans-serif;
	text-align: justify;
	color: var(--textColor);
	letter-spacing: 0.08rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
	margin-top: 1.1rem;
	position: relative;
}
.ah-about-btn-div {
	width: 100%;
	display: flex;
	justify-content: left;
}
.service-details {
	padding: 0;
	margin-bottom: 1.9rem;
	height: 21.875rem;
	transition: 0.4s;
	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	-ms-transition: 0.4s;
	-o-transition: 0.4s;
	background-color: var(--white);
	padding: 2rem 2rem;
	box-shadow: 0 0 1rem 0 var(--shadowColor);
}
.service-details:hover {
	box-shadow: 0 0 3rem 0 var(--shadowColor);
}
.services-link {
	text-decoration: none;
}
.service-heading {
	border: none !important;
	padding: 0.4rem;
	color: var(--black) !important;
}
.service-heading:hover {
	color: var(--white) !important;
}

@media (max-width: 1000px) {
	.ah-container {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
}

/* Footer */
.copyright {
	background-color: var(--PrimaryColor);
	margin-top: 3rem;
	bottom: 0;
}
.copyright h2 {
	vertical-align: middle;
	text-align: center;
	line-height: 6rem;
	margin: 0 !important;
	color: var(--white);
}

/* Invoice */
.invoice-heading {
	background-color: var(--black);
	color: var(--white);
	width: 100%;
	text-align: center;
}
.invoice-logo img {
	width: 25rem;
}
.invoice-top-table {
	width: 21.25rem;
	float: right;
	border-collapse: separate !important;
}
.invoice-top-table td {
	width: 50%;
	padding: 0.8rem;
	border: 0.1rem solid var(--grey);
	border-radius: 0.2rem;
}
.table-head {
	background-color: var(--tableCellBackground);
}
.invoice-bottom-table {
	width: 100%;
	border-collapse: separate !important;
}
.invoice-bottom-table td {
	width: 14%;
	padding: 0.8rem;
	border: 0.1rem solid grey;
	border-radius: 0.2rem;
}
.invoice-bottom-table .td-action {
	width: 14%;
	padding: 0.2rem 1.5rem;
}
.invoice-bottom-table .td-description {
	width: 30%;
}
.align-right-td {
	text-align: right;
}
.invoice-end-table {
	width: 21.25rem;
	float: right;
	border-collapse: separate !important;
}
.invoice-end-table td {
	width: 50%;
	padding: 0.8rem;
	border: 0.1rem solid var(--grey);
	border-radius: 0.2rem;
}
.amount-paid-input {
	height: 3rem;
	border: none;
	width: 100%;
	outline: none;
	padding-right: 0.6rem;
}
.form-btn {
	background-color: var(--PrimaryColor) !important;
	color: var(--white) !important;
}
.addBorder {
	border: 0.1rem solid var(--grey);
}
.invoice-pay-now {
	background-color: var(--payNowBtn);
}
.invoice-pay-now button {
	width: 100%;
	text-align: center;
	color: var(--white);
	background-color: transparent;
	font-size: 1.6em;
}
@media (max-width: 768px) {
	.invoice-top-table {
		float: left;
	}
	.invoice-logo img {
		width: 100%;
		padding: 1rem;
	}
	.invoice-top-table {
		width: 100%;
	}
	.col-12 {
		overflow-x: scroll;
	}
}

/* PDF Generator */
.pdf-width {
	width: 150mm !important;
}
.pdf-logo {
	width: 100% !important;
}
.pdf-invoice-top-table {
	width: 72mm;
	border-collapse: separate !important;
}
.pdf-invoice-top-table td {
	width: 50%;
	font-size: 0.8rem;
	padding: 0.1rem 0.4rem;
	border: 0.05rem solid var(--grey);
	border-radius: 0.2rem;
}
.pdf-font-size {
	font-size: 0.8rem;
}
.pdf-invoice-bottom-table {
	width: 142mm;
}
.pdf-invoice-bottom-table td {
	width: 16%;
	font-size: 0.8rem;
	padding: 0.1rem 0.4rem;
	border: 0.05rem solid var(--grey);
	border-radius: 0.2rem;
}
.pdf-invoice-bottom-table .pdf-td-description {
	width: 36%;
}
.pdf-invoice-end-table {
	width: 72mm;
	float: right;
	border-collapse: separate !important;
}
.pdf-invoice-end-table td {
	width: 50%;
	font-size: 0.8rem;
	padding: 0.1rem 0.4rem;
	border: 0.05rem solid var(--grey);
	border-radius: 0.2rem;
}

/* Error Page  */
.class-404 {
	font-size: 10rem;
	font-weight: 900;
	letter-spacing: 10px;
	background-image: linear-gradient(90deg, #cdf1a3, #ff0048);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

.class-oops {
	font-size: 4rem;
	background-image: linear-gradient(90deg, #152700, #1100ff);
}

.getbackbtn {
	color: white;
	font-size: 2rem;
	font-weight: 600;
	padding: 10px 20px;
	border-radius: 10px;
	outline: none;
	border: none;
	background: linear-gradient(45deg, #a7ff43, #0aefff) !important;
	transition: all 1s;
}

.getbackbtn:focus {
	outline: none;
}

.getbackbtn:hover {
	transform: translateX(2px) translateY(-4px);
}

@media (max-width: 1200px) {
	.class-404 {
		font-size: 8rem;
	}
	.res-oops {
		display: block;
	}
	.class-oops {
		font-size: 3.5rem;
	}
}

@media (max-width: 768px) {
	.class-404 {
		font-size: 6rem;
	}
	.class-oops {
		font-size: 3rem;
	}
}

@media (max-width: 768px) {
	.getbackbtn {
		font-size: 1.5rem;
	}
}
